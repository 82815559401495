import { Component, OnInit } from '@angular/core';
import { Lightbox, IAlbum, LightboxConfig } from 'ngx-lightbox';

class Album implements IAlbum{
  public src: string;
  public caption?: string;
  public thumb: string;
}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  private images :Array<IAlbum> =[
    {thumb:'assets/gallery/image01.jpg', src:'assets/gallery/image01.jpg'},
    {thumb:'assets/gallery/image02.jpg', src:'assets/gallery/image02.jpg'},
    {thumb:'assets/gallery/image03.jpg', src:'assets/gallery/image03.jpg'},
    {thumb:'assets/gallery/image05.jpg', src:'assets/gallery/image05.jpg'},
    {thumb:'assets/gallery/image06.jpg', src:'assets/gallery/image06.jpg'},
    {thumb:'assets/gallery/image07.jpg', src:'assets/gallery/image07.jpg'},
    {thumb:'assets/gallery/image08.jpg', src:'assets/gallery/image08.jpg'},
    {thumb:'assets/gallery/image09.jpg', src:'assets/gallery/image09.jpg'},
    {thumb:'assets/gallery/image10.jpg', src:'assets/gallery/image10.jpg'},
    {thumb:'assets/gallery/image11.jpg', src:'assets/gallery/image11.jpg'},
    {thumb:'assets/gallery/image32.jpg', src:'assets/gallery/image32.jpg'},
    {thumb:'assets/gallery/image33.jpg', src:'assets/gallery/image33.jpg'},
    {thumb:'assets/gallery/image34.jpg', src:'assets/gallery/image34.jpg'},
    {thumb:'assets/gallery/image12.jpg', src:'assets/gallery/image12.jpg'},
    {thumb:'assets/gallery/image13.jpg', src:'assets/gallery/image13.jpg'},
    {thumb:'assets/gallery/image14.jpg', src:'assets/gallery/image14.jpg'},
    {thumb:'assets/gallery/image15.jpg', src:'assets/gallery/image15.jpg'},
    {thumb:'assets/gallery/image16.jpg', src:'assets/gallery/image16.jpg'},
    {thumb:'assets/gallery/image17.jpg', src:'assets/gallery/image17.jpg'},
    {thumb:'assets/gallery/image18.jpg', src:'assets/gallery/image18.jpg'},
    {thumb:'assets/gallery/image19.jpg', src:'assets/gallery/image19.jpg'},
    {thumb:'assets/gallery/image20.jpg', src:'assets/gallery/image20.jpg'},
    {thumb:'assets/gallery/image21.jpg', src:'assets/gallery/image21.jpg'},
    {thumb:'assets/gallery/image22.jpg', src:'assets/gallery/image22.jpg'},
    {thumb:'assets/gallery/image23.jpg', src:'assets/gallery/image23.jpg'},
    {thumb:'assets/gallery/image24.jpg', src:'assets/gallery/image24.jpg'},
    {thumb:'assets/gallery/image25.jpg', src:'assets/gallery/image25.jpg'},
    {thumb:'assets/gallery/image26.jpg', src:'assets/gallery/image26.jpg'},
    {thumb:'assets/gallery/image27.jpg', src:'assets/gallery/image27.jpg'},
    {thumb:'assets/gallery/image28.jpg', src:'assets/gallery/image28.jpg'},
    {thumb:'assets/gallery/image29.jpg', src:'assets/gallery/image29.jpg'},
    {thumb:'assets/gallery/image30.jpg', src:'assets/gallery/image30.jpg'},
    {thumb:'assets/gallery/image31.jpg', src:'assets/gallery/image31.jpg'},
  ];


  constructor(private _lightbox: Lightbox,
    private _lightboxConfig: LightboxConfig) {
      _lightboxConfig.fitImageInViewPort = true;
      _lightboxConfig.centerVertically = true;
      _lightboxConfig.wrapAround = true;
  }

  ngOnInit() {
  }

  public openImage(index:number){
    console.log("openImage",index);
    this._lightbox.open(this.images,index);
  }

}
