import { Component, OnInit } from '@angular/core';
import { Lightbox, IAlbum, LightboxConfig } from 'ngx-lightbox';

class Album implements IAlbum{
  public src: string;
  public caption?: string;
  public thumb: string;
}

@Component({
  selector: 'app-gallery',
  templateUrl: './wedding-gallery.component.html',
  styleUrls: ['./wedding-gallery.component.scss']
})
export class WeddingGalleryComponent implements OnInit {

  // private images :Array<IAlbum> =[
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-1.jpg', src:'assets/wedding-gallery/ConnyArmin-1.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-2.jpg', src:'assets/wedding-gallery/ConnyArmin-2.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-3.jpg', src:'assets/wedding-gallery/ConnyArmin-3.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-4.jpg', src:'assets/wedding-gallery/ConnyArmin-4.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-5.jpg', src:'assets/wedding-gallery/ConnyArmin-5.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-6.jpg', src:'assets/wedding-gallery/ConnyArmin-6.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-7.jpg', src:'assets/wedding-gallery/ConnyArmin-7.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-8.jpg', src:'assets/wedding-gallery/ConnyArmin-8.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-9.jpg', src:'assets/wedding-gallery/ConnyArmin-9.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-10.jpg', src:'assets/wedding-gallery/ConnyArmin-10.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-11.jpg', src:'assets/wedding-gallery/ConnyArmin-11.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-12.jpg', src:'assets/wedding-gallery/ConnyArmin-12.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-13.jpg', src:'assets/wedding-gallery/ConnyArmin-13.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-14.jpg', src:'assets/wedding-gallery/ConnyArmin-14.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-15.jpg', src:'assets/wedding-gallery/ConnyArmin-15.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-16.jpg', src:'assets/wedding-gallery/ConnyArmin-16.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-17.jpg', src:'assets/wedding-gallery/ConnyArmin-17.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-18.jpg', src:'assets/wedding-gallery/ConnyArmin-18.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-19.jpg', src:'assets/wedding-gallery/ConnyArmin-19.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-20.jpg', src:'assets/wedding-gallery/ConnyArmin-20.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-21.jpg', src:'assets/wedding-gallery/ConnyArmin-21.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-22.jpg', src:'assets/wedding-gallery/ConnyArmin-22.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-23.jpg', src:'assets/wedding-gallery/ConnyArmin-23.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-24.jpg', src:'assets/wedding-gallery/ConnyArmin-24.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-25.jpg', src:'assets/wedding-gallery/ConnyArmin-25.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-26.jpg', src:'assets/wedding-gallery/ConnyArmin-26.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-27.jpg', src:'assets/wedding-gallery/ConnyArmin-27.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-28.jpg', src:'assets/wedding-gallery/ConnyArmin-28.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-29.jpg', src:'assets/wedding-gallery/ConnyArmin-29.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-30.jpg', src:'assets/wedding-gallery/ConnyArmin-30.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-31.jpg', src:'assets/wedding-gallery/ConnyArmin-31.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-32.jpg', src:'assets/wedding-gallery/ConnyArmin-32.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-33.jpg', src:'assets/wedding-gallery/ConnyArmin-33.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-34.jpg', src:'assets/wedding-gallery/ConnyArmin-34.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-35.jpg', src:'assets/wedding-gallery/ConnyArmin-35.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-36.jpg', src:'assets/wedding-gallery/ConnyArmin-36.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-37.jpg', src:'assets/wedding-gallery/ConnyArmin-37.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-38.jpg', src:'assets/wedding-gallery/ConnyArmin-38.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-39.jpg', src:'assets/wedding-gallery/ConnyArmin-39.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-40.jpg', src:'assets/wedding-gallery/ConnyArmin-40.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-41.jpg', src:'assets/wedding-gallery/ConnyArmin-41.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-42.jpg', src:'assets/wedding-gallery/ConnyArmin-42.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-43.jpg', src:'assets/wedding-gallery/ConnyArmin-43.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-44.jpg', src:'assets/wedding-gallery/ConnyArmin-44.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-45.jpg', src:'assets/wedding-gallery/ConnyArmin-45.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-46.jpg', src:'assets/wedding-gallery/ConnyArmin-46.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-47.jpg', src:'assets/wedding-gallery/ConnyArmin-47.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-48.jpg', src:'assets/wedding-gallery/ConnyArmin-48.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-49.jpg', src:'assets/wedding-gallery/ConnyArmin-49.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-50.jpg', src:'assets/wedding-gallery/ConnyArmin-50.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-51.jpg', src:'assets/wedding-gallery/ConnyArmin-51.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-52.jpg', src:'assets/wedding-gallery/ConnyArmin-52.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-53.jpg', src:'assets/wedding-gallery/ConnyArmin-53.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-54.jpg', src:'assets/wedding-gallery/ConnyArmin-54.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-55.jpg', src:'assets/wedding-gallery/ConnyArmin-55.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-56.jpg', src:'assets/wedding-gallery/ConnyArmin-56.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-57.jpg', src:'assets/wedding-gallery/ConnyArmin-57.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-58.jpg', src:'assets/wedding-gallery/ConnyArmin-58.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-59.jpg', src:'assets/wedding-gallery/ConnyArmin-59.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-60.jpg', src:'assets/wedding-gallery/ConnyArmin-60.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-61.jpg', src:'assets/wedding-gallery/ConnyArmin-61.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-62.jpg', src:'assets/wedding-gallery/ConnyArmin-62.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-63.jpg', src:'assets/wedding-gallery/ConnyArmin-63.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-64.jpg', src:'assets/wedding-gallery/ConnyArmin-64.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-65.jpg', src:'assets/wedding-gallery/ConnyArmin-65.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-66.jpg', src:'assets/wedding-gallery/ConnyArmin-66.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-67.jpg', src:'assets/wedding-gallery/ConnyArmin-67.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-68.jpg', src:'assets/wedding-gallery/ConnyArmin-68.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-69.jpg', src:'assets/wedding-gallery/ConnyArmin-69.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-70.jpg', src:'assets/wedding-gallery/ConnyArmin-70.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-71.jpg', src:'assets/wedding-gallery/ConnyArmin-71.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-72.jpg', src:'assets/wedding-gallery/ConnyArmin-72.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-73.jpg', src:'assets/wedding-gallery/ConnyArmin-73.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-74.jpg', src:'assets/wedding-gallery/ConnyArmin-74.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-75.jpg', src:'assets/wedding-gallery/ConnyArmin-75.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-76.jpg', src:'assets/wedding-gallery/ConnyArmin-76.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-77.jpg', src:'assets/wedding-gallery/ConnyArmin-77.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-78.jpg', src:'assets/wedding-gallery/ConnyArmin-78.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-79.jpg', src:'assets/wedding-gallery/ConnyArmin-79.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-80.jpg', src:'assets/wedding-gallery/ConnyArmin-80.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-81.jpg', src:'assets/wedding-gallery/ConnyArmin-81.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-82.jpg', src:'assets/wedding-gallery/ConnyArmin-82.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-83.jpg', src:'assets/wedding-gallery/ConnyArmin-83.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-84.jpg', src:'assets/wedding-gallery/ConnyArmin-84.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-85.jpg', src:'assets/wedding-gallery/ConnyArmin-85.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-86.jpg', src:'assets/wedding-gallery/ConnyArmin-86.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-87.jpg', src:'assets/wedding-gallery/ConnyArmin-87.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-88.jpg', src:'assets/wedding-gallery/ConnyArmin-88.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-89.jpg', src:'assets/wedding-gallery/ConnyArmin-89.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-90.jpg', src:'assets/wedding-gallery/ConnyArmin-90.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-91.jpg', src:'assets/wedding-gallery/ConnyArmin-91.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-92.jpg', src:'assets/wedding-gallery/ConnyArmin-92.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-93.jpg', src:'assets/wedding-gallery/ConnyArmin-93.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-94.jpg', src:'assets/wedding-gallery/ConnyArmin-94.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-95.jpg', src:'assets/wedding-gallery/ConnyArmin-95.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-96.jpg', src:'assets/wedding-gallery/ConnyArmin-96.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-97.jpg', src:'assets/wedding-gallery/ConnyArmin-97.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-98.jpg', src:'assets/wedding-gallery/ConnyArmin-98.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-99.jpg', src:'assets/wedding-gallery/ConnyArmin-99.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-100.jpg', src:'assets/wedding-gallery/ConnyArmin-100.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-101.jpg', src:'assets/wedding-gallery/ConnyArmin-101.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-102.jpg', src:'assets/wedding-gallery/ConnyArmin-102.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-103.jpg', src:'assets/wedding-gallery/ConnyArmin-103.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-104.jpg', src:'assets/wedding-gallery/ConnyArmin-104.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-105.jpg', src:'assets/wedding-gallery/ConnyArmin-105.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-106.jpg', src:'assets/wedding-gallery/ConnyArmin-106.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-107.jpg', src:'assets/wedding-gallery/ConnyArmin-107.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-108.jpg', src:'assets/wedding-gallery/ConnyArmin-108.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-109.jpg', src:'assets/wedding-gallery/ConnyArmin-109.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-110.jpg', src:'assets/wedding-gallery/ConnyArmin-110.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-111.jpg', src:'assets/wedding-gallery/ConnyArmin-111.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-112.jpg', src:'assets/wedding-gallery/ConnyArmin-112.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-113.jpg', src:'assets/wedding-gallery/ConnyArmin-113.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-114.jpg', src:'assets/wedding-gallery/ConnyArmin-114.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-115.jpg', src:'assets/wedding-gallery/ConnyArmin-115.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-116.jpg', src:'assets/wedding-gallery/ConnyArmin-116.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-117.jpg', src:'assets/wedding-gallery/ConnyArmin-117.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-118.jpg', src:'assets/wedding-gallery/ConnyArmin-118.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-119.jpg', src:'assets/wedding-gallery/ConnyArmin-119.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-120.jpg', src:'assets/wedding-gallery/ConnyArmin-120.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-121.jpg', src:'assets/wedding-gallery/ConnyArmin-121.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-122.jpg', src:'assets/wedding-gallery/ConnyArmin-122.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-123.jpg', src:'assets/wedding-gallery/ConnyArmin-123.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-124.jpg', src:'assets/wedding-gallery/ConnyArmin-124.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-125.jpg', src:'assets/wedding-gallery/ConnyArmin-125.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-126.jpg', src:'assets/wedding-gallery/ConnyArmin-126.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-127.jpg', src:'assets/wedding-gallery/ConnyArmin-127.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-128.jpg', src:'assets/wedding-gallery/ConnyArmin-128.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-129.jpg', src:'assets/wedding-gallery/ConnyArmin-129.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-130.jpg', src:'assets/wedding-gallery/ConnyArmin-130.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-131.jpg', src:'assets/wedding-gallery/ConnyArmin-131.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-132.jpg', src:'assets/wedding-gallery/ConnyArmin-132.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-133.jpg', src:'assets/wedding-gallery/ConnyArmin-133.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-134.jpg', src:'assets/wedding-gallery/ConnyArmin-134.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-135.jpg', src:'assets/wedding-gallery/ConnyArmin-135.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-136.jpg', src:'assets/wedding-gallery/ConnyArmin-136.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-137.jpg', src:'assets/wedding-gallery/ConnyArmin-137.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-138.jpg', src:'assets/wedding-gallery/ConnyArmin-138.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-139.jpg', src:'assets/wedding-gallery/ConnyArmin-139.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-140.jpg', src:'assets/wedding-gallery/ConnyArmin-140.jpg'},
  //   {thumb:'assets/wedding-gallery/thumbs/ConnyArmin-141.jpg', src:'assets/wedding-gallery/ConnyArmin-141.jpg'},
  // ];


  constructor(private _lightbox: Lightbox,
    private _lightboxConfig: LightboxConfig) {
      _lightboxConfig.fitImageInViewPort = true;
      _lightboxConfig.centerVertically = true;
      _lightboxConfig.wrapAround = true;
  }

  ngOnInit() {
  }

  public openImage(index:number){
    console.log("openImage",index);
    this._lightbox.open(this.getAlbum(),index);
  }

  public getAlbum(): Array<IAlbum> {
    const indices = Array.from(Array(244).keys());
    return indices.map(key => {
      const fileName = `MM-${key+1}.jpg`;
      return {
        src: `assets/wedding-gallery/${fileName}`,
        thumb: `assets/wedding-gallery/thumbs/${fileName}`,
      }
    });
  }

}
