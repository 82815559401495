import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-home',
  styles:['margin-top: 100px;'],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  private componentWidth : number = 0;

  constructor(private ref: ElementRef) { }

  ngOnInit() {
    var clientRect = this.ref.nativeElement.getBoundingClientRect();
    this.componentWidth = clientRect.width;    
  }

}
